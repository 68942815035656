<template>
    <div class="prtf-page" v-if="client && portfolio">
        <div class="prtf-page-header">
            <div class="prtf-page-header-title-holder">
                <div class="prtf-page-header-maintitle">
                    <div class="prtf-page-header-name">Analytics</div>
                </div>
                <div class="prtf-page-header-subtitle">
                    <ClientHeaderLink :client="client" />
                    <PortfolioHeaderLink :portfolio="portfolio" :clientid="client.id" />
                </div>
            </div>
            <div class="prtf-page-header-buttons">
                <div class="prtf-page-header-button">
                    <el-button type="text" @click="get_data"><svg-icon icon-class="refresh" /></el-button>
                </div>
                <div class="prtf-page-header-button">
                    <PeriodPicker
                        :period_from="period_from"
                        :period_to="period_to"
                        @change="change_period" />
                </div>
                <div class="prtf-page-header-button">
                    <el-select v-model="mode" @change="get_data">
                        <el-option label="By value date" value="value"></el-option>
                        <el-option label="By trade date" value="trade"></el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div class="prtf-page-body prtf-page-body-flex">
            <div class="prtf-page-item prtf-page-item-left prtf-page-item-quarter-1">
                <Benchmarking :portfolio="portfolio" />
            </div>
            <div class="prtf-page-item prtf-page-item-left prtf-page-item-quarter-1">
                <RiskFreeRates :portfolio="portfolio" />
            </div>
            <div class="prtf-page-item prtf-page-item-full">
                <Summary 
                    :clientid="client._id"
                    :portfolioid="portfolioid"
                    :mode="mode"
                    :period_from="period_from"
                    :period_to="period_to"
                    ref="Summary" />
            </div>
            <!-- <div class="prtf-page-item prtf-page-item-left prtf-page-item-quarter-3">
                <TWR 
                    :clientid="client._id"
                    :portfolioid="portfolioid"
                    :mode="mode"
                    :period_from="period_from"
                    :period_to="period_to"
                    ref="TWR" />
            </div>
            <div class="prtf-page-item prtf-page-item-right prtf-page-item-quarter-1">
                <MWR 
                    :clientid="client._id"
                    :portfolioid="portfolioid"
                    :mode="mode"
                    :period_from="period_from"
                    :period_to="period_to"
                    ref="MWR" />
            </div> -->
        </div>
    </div>
</template>

<script>
import PeriodPicker from '@/components/PageComponents/PeriodPicker'
import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'
import PortfolioHeaderLink from '@/components/PageComponents/PortfolioHeaderLink'

// import TWR from './TWR'
// import MWR from './MWR'
import Summary from './Summary'
import Benchmarking from './Benchmarking'
import RiskFreeRates from './RiskFreeRates'

export default {
    name: 'portfolio_analytics',
    components: { 
        PeriodPicker, 
        ClientHeaderLink, 
        PortfolioHeaderLink, 
        Summary,
        Benchmarking,
        RiskFreeRates,
    },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
    },

    data(){
        return {
            client      : undefined,
            portfolio   : undefined,
            loading     : false,
            period_from : this.$moment().add({months:-3}).toDate(),
            period_to   : this.$moment().toDate(),
            mode        : 'trade',
        }
    },

    methods: {
        change_period($event){
            this.period_from = $event[0];
            this.period_to   = $event[1];

            this.$nextTick(function () {
                this.get_data();
            });
        },

        get_data(){
            if (this.$refs.TWR) this.$refs.TWR.get_list();
            if (this.$refs.MWR) this.$refs.MWR.get_list();
            if (this.$refs.Summary) this.$refs.Summary.get_list();
        }
    },

    mounted(){
        if (this.clientid && this.portfolioid) {
            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                this.$store.dispatch('portfolio/getPortfolio', this.portfolioid).then((portfolio_response) => {
                    this.portfolio = portfolio_response
                    
                    this.$emit('tab-title-change', 'Analytics for ' + this.portfolio.name + ' for ' + this.client.first_name + ' ' + this.client.last_name)
                })
            })
        }
    }
}
</script>
